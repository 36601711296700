import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUser } from "../../../../redux/actions/adminActions";
import { Col, Container, Row } from "react-bootstrap";
import ProfileBar from "./ProfileBar";
import TradingAccounts from "./TradingAccounts";
import FundingHistory from "./FundingHistory";
import ClientLog from "./ClientLog";
import Compliance from "./Compliance";

const Profile = props => {
  const [user, setUser] = useState(false);

  useEffect(() => {
    getUser(props.match.params.id)
      .then(res => {
        setUser(res.data.user);
      })
      .catch(err => console.log(err));
  }, [props.match.params.id]);

  return (
    <Container fluid>
      {user ? (
        <Row>
          <Col>
            <ProfileBar userData={user} />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col md="8">
          <TradingAccounts userId={props.match.params.id} />
          <FundingHistory userId={props.match.params.id} />
        </Col>
        <Col md="4">
          <ClientLog userId={props.match.params.id} />
          <Compliance userId={props.match.params.id} />
        </Col>
      </Row>
    </Container>
  );
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Profile);
