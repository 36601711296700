import React from "react";
import CryptoJS from "crypto-js";
import { encryptionKey } from "../config/config";
import {
  userStatuses,
  accountStatuses,
  requestsStatuses,
  documentStatuses,
  countryList,
  userRoles,
} from "../config/constants";

export const encryptData = data => {
  return CryptoJS.AES.encrypt(data, encryptionKey).toString();
};

export const decryptData = data => {
  return CryptoJS.AES.decrypt(data, encryptionKey).toString(CryptoJS.enc.Utf8);
};

export const encryptDataBase64 = data => {
  return CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(data.toString())
  );
};

export const decryptDataBase64 = data => {
  return CryptoJS.enc.Base64.parse(data.toString()).toString(CryptoJS.enc.Utf8);
};

export const cutLongText = (text, limit) => {
  const lmt = parseInt(limit);
  return text.length > lmt ? text.substr(0, lmt) + "..." : text;
};

export const getUserStatusSelectListOptions = () =>
  Object.keys(userStatuses).map(userKey => (
    <option key={userKey} value={userKey}>
      {userStatuses[userKey]}
    </option>
  ));

export const getDocumentStatusSelectListOptions = () =>
  Object.keys(documentStatuses).map(docKey => (
    <option key={docKey} value={docKey}>
      {documentStatuses[docKey]}
    </option>
  ));

export const getCountrySelectListOptions = () =>
  Object.keys(countryList).map(countryKey => (
    <option key={countryKey} value={countryKey}>
      {countryList[countryKey]}
    </option>
  ));

export const countryListReactSelect = () => {
  let countries = [];
  Object.keys(countryList).map((value, index) =>
    countries.push({ value: value, label: countryList[value] })
  );
  return countries;
};

export const formatDateForDateFilter = date => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getBirthDaysReactSelect = () => {
  let days = [];
  for (let i = 1; i <= 31; i++) {
    days.push({ value: i, label: i });
  }
  return days;
};

export const getBirthMonthsReactSelect = () => {
  let months = [];
  for (let i = 1; i <= 12; i++) {
    months.push({ value: i, label: i });
  }
  return months;
};

export const getBirthYearsReactSelect = () => {
  let d = new Date();
  let y = d.getFullYear();

  let years = [];
  for (let i = y - 100; i <= y; i++) {
    years.push({ value: i, label: i });
  }
  return years;
};

export const getExpiryYearsReactSelect = () => {
  let d = new Date();
  let y = d.getFullYear();

  let years = [];
  for (let i = y; i <= y + 50; i++) {
    years.push({ value: i, label: i });
  }
  return years;
};

export const getUserRolesSelectListOptions = userRole =>
  Object.keys(userRoles).map(role => {
    if (userRole > userRoles[role]) {
      return (
        <option key={role} value={role}>
          {role}
        </option>
      );
    }
  });

export const getAccountStatusSelectListOptions = () =>
  Object.keys(accountStatuses).map(accKey => (
    <option key={accKey} value={accKey}>
      {accountStatuses[accKey]}
    </option>
  ));

export const getRequestStatusSelectListOptions = () =>
  Object.keys(requestsStatuses).map(accKey => (
    <option key={accKey} value={accKey}>
      {requestsStatuses[accKey]}
    </option>
  ));

export const getReducedAmount = (amount, reducer) => {
  return reducer.includes("%")
    ? amount - amount * (parseFloat(reducer) / 100)
    : amount - parseFloat(reducer);
};

export const getCategoryName = () => {
  if (window.location.href.includes("trading")) {
    return "MY ACCOUNTS";
  } else if (
    window.location.href.includes("funds") ||
    window.location.href.includes("trans")
  ) {
    return "FUNDING";
  } else if (window.location.href.includes("download")) {
    return "DOWNLOADS";
  } else if (window.location.href.includes("document")) {
    return "VERIFICATION";
  } else return "";
};

export const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
