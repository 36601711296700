import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./redux/utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./redux/actions/authActions";
import {
  checkNewDeposits,
  getNewRequestsCount,
  setMenuCounters,
} from "./redux/actions/adminActions";
import { Provider } from "react-redux";
import store from "./redux/store";
// import IdleTimer from "react-idle-timer";
import Layout from "./hoc/Layout/Layout";
import { NotificationManager } from "react-notifications";

// Admin section
import AdminRoute from "./components/AdminRoute/AdminRoute";
import AdminDashboard from "./containers/Admin/Dashboard/Dashboard";
import AdminUsers from "./containers/Admin/Users/UserList/UserList";
import AdminAddUser from "./containers/Admin/Users/AddUser/AddUser";
import AdminProfile from "./containers/Admin/Users/Profile/Profile";
import AdminClientApplication from "./containers/Admin/Users/Profile/ClientApplication";
import ManageGroups from "./containers/Admin/ManageGroups/ManageGroups";
import HistoricalRequests from "./containers/Admin/HistoricalRequests/HistoricalRequests";
import WalletAccounts from "./containers/Admin/WalletAccounts/WalletAccounts";
import TradingAccounts from "./containers/Admin/TradingAccounts/TradingAccounts";
import IbCodes from "./containers/Admin/IbCodes/IbCodes";
import TransferRequests from "./containers/Admin/TransferRequests/TransferRequests";
import WithdrawalRequests from "./containers/Admin/WithdrawalRequests/WithdrawalRequests";
import DepositRequests from "./containers/Admin/DepositRequests/DepositRequests";
import BankAccountRequests from "./containers/Admin/BankAccountRequests/BankAccountRequests";
import ChangeLeverageRequests from "./containers/Admin/ChangeLeverageRequests/ChangeLeverageRequests";
import NewSubAccountRequests from "./containers/Admin/NewSubAccountRequests/NewSubAccountRequests";

// User section
import Login from "./containers/Auth/Login/Login";
import ConfirmEmail from "./containers/Auth/ConfirmEmail/ConfirmEmail";
import ForgotPassword from "./containers/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword/ResetPassword";

import "./App.css";

// PLUGINS
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-notifications/lib/notifications.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/login";
  }
}

class App extends Component {
  state = {
    newRequests: {
      newClients: 0,
      newDeposits: 0,
      newWithdrawals: 0,
      newTransfers: 0,
      newBankDetails: 0,
      newSubAccounts: 0,
      newChangeLeverages: 0,
    },
  };

  // constructor(props) {
  //   super(props);
  //
  //   this.state = {
  //     timeout: 1000 * 60 * 10, // 10 minutes
  //     showModal: false,
  //     userLoggedIn: false,
  //     isTimedOut: false
  //   };
  //
  //   this.idleTimer = null;
  //   this.onAction = this._onAction.bind(this);
  //   this.onActive = this._onActive.bind(this);
  //   this.onIdle = this._onIdle.bind(this);
  // }
  //
  // _onAction(e) {
  //   // console.log("user did something", e);
  //   this.setState({ isTimedOut: false });
  // }
  //
  // _onActive(e) {
  //   // console.log("user is active", e);
  //   this.setState({ isTimedOut: false });
  // }
  //
  // _onIdle(e) {
  //   // console.log("user is idle", e);
  //   const isTimedOut = this.state.isTimedOut;
  //   if (isTimedOut && !window.location.href.includes("login")) {
  //     // Logout user
  //     store.dispatch(logoutUser());
  //     // Redirect to login
  //     window.location.href = "/login";
  //   } else {
  //     this.setState({ showModal: true });
  //     this.idleTimer.reset();
  //     this.setState({ isTimedOut: true });
  //   }
  // }

  triggerReadNewRequests = () => {
    getNewRequestsCount()
      .then(res => {
        const newRequests = res.data;
        if (
          JSON.stringify(this.state.newRequests) !== JSON.stringify(newRequests)
        ) {
          const newState = {
            newRequests,
          };

          store.dispatch(setMenuCounters(newRequests));
          this.setState(newState);
        }

        setTimeout(this.triggerReadNewRequests, 10000);
      })
      .catch(err => console.log(err));
  };

  triggerNewDeposits = () => {
    checkNewDeposits()
      .then(res => {
        if (res.data.notify) {
          NotificationManager.success("You have new deposits.", "", 0);
        }

        setTimeout(this.triggerNewDeposits, 10000);
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    this.triggerNewDeposits();
    this.triggerReadNewRequests();
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          {/*<IdleTimer*/}
          {/*  ref={ref => {*/}
          {/*    this.idleTimer = ref;*/}
          {/*  }}*/}
          {/*  element={document}*/}
          {/*  onActive={this.onActive}*/}
          {/*  onIdle={this.onIdle}*/}
          {/*  onAction={this.onAction}*/}
          {/*  debounce={250}*/}
          {/*  timeout={this.state.timeout}*/}
          {/*  events={["keydown", "mousedown"]}*/}
          {/*/>*/}

          <Layout>
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />

            <Switch>
              {/* ADMIN ROUTES */}
              <AdminRoute exact path="/" component={AdminDashboard} />
              <AdminRoute exact path="/users/:status" component={AdminUsers} />
              <AdminRoute exact path="/add-user" component={AdminAddUser} />
              <AdminRoute
                exact
                path="/user/profile/:id"
                component={AdminProfile}
              />
              <AdminRoute
                exact
                path="/user/application/:id"
                component={AdminClientApplication}
              />
              <AdminRoute
                exact
                path="/manage-groups"
                component={ManageGroups}
              />
              <AdminRoute
                exact
                path="/historical-requests"
                component={HistoricalRequests}
              />
              <AdminRoute
                exact
                path="/wallet-accounts"
                component={WalletAccounts}
              />
              <AdminRoute
                exact
                path="/trading-accounts"
                component={TradingAccounts}
              />
              <AdminRoute exact path="/ib-codes" component={IbCodes} />
              <AdminRoute
                exact
                path="/transfer-requests/:status"
                component={TransferRequests}
              />
              <AdminRoute
                exact
                path="/withdrawal-requests/:status"
                component={WithdrawalRequests}
              />
              <AdminRoute
                exact
                path="/deposit-requests/:status"
                component={DepositRequests}
              />
              <AdminRoute
                exact
                path="/bank-verification-requests/:status"
                component={BankAccountRequests}
              />
              <AdminRoute
                exact
                path="/change-leverage-requests/:status"
                component={ChangeLeverageRequests}
              />
              <AdminRoute
                exact
                path="/new-sub-account-requests/:status"
                component={NewSubAccountRequests}
              />
            </Switch>

            <Route path="/email/confirm/:id+" component={ConfirmEmail} />
            <Route
              path="/email/confirm/:setpassword/:id+"
              component={ConfirmEmail}
            />
            <Route path="/resetpassword/:id+" component={ResetPassword} />
          </Layout>
        </Router>
      </Provider>
    );
  }
}

export default App;
