import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  adminGetAccounts,
  adminUpdateAccount,
  clearSuccessErrorProps,
} from "../../../redux/actions/adminActions";
import {
  // filterAccountPlatform,
  filterAccountType,
  filterBaseCurrency,
} from "../../../config/constants";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import { getAccountStatusSelectListOptions } from "../../../utils/utils";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import { NotificationManager } from "react-notifications";

const SubAccounts = props => {
  const [state, setState] = useState({
    editAccountErrors: "",
    showAccountModal: false,
    disableEditAccountBtn: false,
    selectedAccount: {},
    loading: true,
  });
  const [accountsLive, setLiveAccounts] = useState(null);
  const [usersLive, setLiveUsers] = useState(null);

  useEffect(() => {
    triggerAdminGetAccounts();
  }, [props.match.params.status]);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      createNotification("success");

      clearSuccessErrorProps();
      handleCloseModal();
      triggerAdminGetAccounts();
    }

    if (
      Object.keys(props.errors).length > 0 &&
      props.errors.account !== undefined
    ) {
      createNotification("error");

      setState(prevState => {
        return {
          ...prevState,
          editAccountErrors: props.errors.account,
        };
      });
      clearSuccessErrorProps();
    }
  }, [props]);

  const triggerAdminGetAccounts = () => {
    setLiveAccounts([]);
    setState(prevState => {
      return {
        ...prevState,
        loading: true,
      };
    });
    adminGetAccounts("all", "live", props.match.params.status)
      .then(res => {
        let users = {};
        res.data.users.map(user => (users[user._id] = user));
        setLiveUsers(users);

        setLiveAccounts(res.data.accounts);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.error(err));
  };

  const handleUpdateAccount = e => {
    let name = e.target.name;
    let value = e.target.value;

    const selectedAccount = state.selectedAccount;
    selectedAccount[name] = value;
    setState(prevState => {
      return { ...prevState, selectedAccount };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        disableEditAccountBtn: false,
        showAccountModal: false,
      };
    });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const selectedAccount = state.selectedAccount;

    if (
      selectedAccount.status === "rejected" &&
      (!selectedAccount.comment || selectedAccount.comment.trim() === "")
    ) {
      alert("You cannot reject account without a reason");
      return false;
    }

    setState(prevState => {
      return { ...prevState, disableEditAccountBtn: true };
    });

    if (form.checkValidity()) {
      props.adminUpdateAccount(selectedAccount);
    }
  };

  const createNotification = type => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the account.",
          "Account Update",
          5000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong!", "Error", 5000);
        break;
      default:
    }
  };

  const customFilterCurrency = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.baseCurrency === filterVal);
    }
    return data;
  };

  const customFilterAccountPlatform = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.platform === filterVal);
    }
    return data;
  };

  const customFilterAccountType = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.type === filterVal);
    }
    return data;
  };

  const columnsLive = [
    {
      dataField: "fullName",
      text: "FULL NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "type",
      text: "ACC. TYPE",
      sort: true,
      filter: selectFilter({
        options: filterAccountType,
        onFilter: customFilterAccountType,
      }),
    },
    {
      dataField: "platform",
      text: "PLATFORM",
      // sort: true,
      // filter: selectFilter({
      //   options: filterAccountPlatform,
      //   onFilter: customFilterAccountPlatform,
      // }),
    },
    {
      dataField: "baseCurrency",
      text: "BASE CURRENCY",
      sort: true,
      filter: selectFilter({
        options: filterBaseCurrency,
        onFilter: customFilterCurrency,
      }),
    },
    {
      dataField: "timeCreatedText",
      text: "REQUESTED",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timeCreatedText",
      order: "asc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setState(prevState => {
        return { ...prevState, showAccountModal: true, selectedAccount: row };
      });
    },
  };

  let dataLive = [];
  if (!!accountsLive) {
    accountsLive.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      accountsLive[keyIndex].timeCreatedText = moment(
        accountsLive[keyIndex].timeCreated * 1000
      )
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      if (usersLive && usersLive[accountsLive[keyIndex].userId]) {
        accountsLive[keyIndex].fullName = `${
          usersLive[accountsLive[keyIndex].userId].name
        } ${usersLive[accountsLive[keyIndex].userId].surname}`;
        accountsLive[keyIndex].email =
          usersLive[accountsLive[keyIndex].userId].email;
      }

      dataLive.push(accountsLive[keyIndex]);
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="pt-3 px-3">
            New Sub Account Requests -{" "}
            <span className="text-capitalize">{props.match.params.status}</span>{" "}
            {state.loading ? <Spinner animation="border" /> : null}
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "pending" ? "disabled" : "",
              ]}
              as={Link}
              to={"/new-sub-account-requests/pending"}
            >
              Pending
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "approved" ? "disabled" : "",
              ]}
              as={Link}
              to={"/new-sub-account-requests/approved"}
            >
              Approved
            </Button>
            <Button
              type="button"
              variant="secondary"
              className={[
                "grayButton ml-3",
                props.match.params.status === "rejected" ? "disabled" : "",
              ]}
              as={Link}
              to={"/new-sub-account-requests/rejected"}
            >
              Rejected
            </Button>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <BootstrapTable
            bootstrap4
            keyField="_id"
            data={dataLive}
            columns={columnsLive}
            defaultSorted={defaultSorted}
            hover={true}
            filter={filterFactory()}
            rowEvents={rowEvents}
            headerClasses="tableHeaderClass"
          />

          <Modal
            show={state.showAccountModal}
            onHide={handleCloseModal}
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title>Update account</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={e => handleSubmit(e)}>
                <Form.Group>
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.fullName}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.email}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Acc. type</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.type}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Platform</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.platform}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Base currency</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    defaultValue={state.selectedAccount.baseCurrency}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    required
                    onChange={e => handleUpdateAccount(e)}
                    defaultValue={state.selectedAccount.status}
                    disabled={props.match.params.status !== "pending"}
                  >
                    <option value="">Choose account status</option>
                    {getAccountStatusSelectListOptions()}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    name="comment"
                    onChange={e => handleUpdateAccount(e)}
                    defaultValue={state.selectedAccount.comment}
                  />
                </Form.Group>

                <Form.Text style={{ color: "red" }}>
                  {state.editAccountErrors.errors ||
                    state.editAccountErrors.server}
                  <br />
                  <br />
                </Form.Text>

                <Form.Row className="justify-content-between">
                  <Button
                    variant="secondary"
                    className="greyButton"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="primary"
                    type="submit"
                    className="darkBlueButton"
                    style={{ fontWeight: "normal" }}
                    disabled={state.disableEditAccountBtn}
                  >
                    {state.disableEditAccountBtn ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Update
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Form.Row>
              </Form>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

SubAccounts.propTypes = {
  auth: PropTypes.object.isRequired,
  adminUpdateAccount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { adminUpdateAccount })(SubAccounts);
