import React from "react";
import classes from "./Layout.module.css";
import Navbar from "../../components/Navigation/Navbar";
import SidebarAdmin from "../../components/Navigation/SidebarAdmin";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../redux/actions/authActions";
import { Col, Container, Row } from "react-bootstrap";
import { NotificationContainer } from "react-notifications";

const Layout = props => {
  const onLogoutClick = e => {
    e.preventDefault();
    props.logoutUser();
    window.location.href = "/login";
  };

  const HeaderWithRouter = withRouter(Navbar);

  let Sidebar = props.auth.isAuthenticated ? withRouter(SidebarAdmin) : null;

  return (
    <>
      <Container style={{ height: "100vh" }} fluid>
        <Row className="h-100">
          {Sidebar ? (
            <Col className="sidebar px-0">
              <Sidebar />
            </Col>
          ) : null}
          <Col
            className={[
              classes.Content,
              !props.auth.isAuthenticated ? "mx-auto" : "",
            ].join(" ")}
          >
            <HeaderWithRouter logoutClicked={onLogoutClick} />
            {props.children}
          </Col>
        </Row>
      </Container>

      <NotificationContainer />
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Layout);
