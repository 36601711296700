import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/iQuoto-Global-Logo-White.svg";
import SidebarCounter from "../SidebarCounter/SidebarCounter";
import { adminGetMlStatuses } from "../../redux/actions/adminActions";
import moment from "moment";

const SidebarAdmin = props => {
  const [activeItem, setActiveItem] = useState("");
  const [mlStatuses, setMlStatuses] = useState([]);

  useEffect(() => {
    triggerLoadMlStatuses();

    const statusChecker = setInterval(triggerLoadMlStatuses, 5000);

    setActiveItem(window.location.pathname);

    // Clean up setInterval
    return () => {
      clearInterval(statusChecker);
    };
  }, []);

  const triggerLoadMlStatuses = () => {
    adminGetMlStatuses()
      .then(res => {
        setMlStatuses(res.data);
      })
      .catch(err => console.log(err));
  };

  const handleMenuItemClicked = destination => {
    setActiveItem(destination);
  };

  return (
    <>
      <object
        data={logo}
        width="172"
        height="65"
        className="sidebarLogo sidebarLogoAdmin"
        aria-label="iQuoto.com"
      />

      <div className="sidebarUser">
        <div className="menuItemTitle">ML STATUSES</div>
        <div className="d-flex justify-content-center">
          {mlStatuses.map(ml => (
            <span
              key={ml.platform}
              title={`Last working: ${moment(ml.lastWorking * 1000)
                .format("YYYY/MM/DD HH:mm:ss")
                .toString()}`}
              className={[
                "d-inline-block mx-1",
                "ml_status",
                ml.status === "OK" ? "ml_status_on" : "ml_status_off",
              ].join(" ")}
            >
              {ml.platform}
            </span>
          ))}
        </div>

        <div className="menuItemTitle">CLIENT MANAGEMENT</div>
        <Button
          as={Link}
          to="/users/all"
          onClick={() => handleMenuItemClicked("/users/all")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/all") ? "active" : null,
          ]}
        >
          All Accounts
        </Button>
        <Button
          as={Link}
          to="/users/new"
          onClick={() => handleMenuItemClicked("/users/new")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/new") ? "active" : null,
          ]}
        >
          New Clients
          {props.global.newRequests.newClients > 0 ? (
            <SidebarCounter number={props.global.newRequests.newClients} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/users/approved"
          onClick={() => handleMenuItemClicked("/users/approved")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/approved") ? "active" : null,
          ]}
        >
          Approved Accounts
        </Button>
        <Button
          as={Link}
          to="/users/pending"
          onClick={() => handleMenuItemClicked("/users/pending")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/pending") ? "active" : null,
          ]}
        >
          Pending Accounts
        </Button>
        <Button
          as={Link}
          to="/users/rejected"
          onClick={() => handleMenuItemClicked("/users/rejected")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/rejected") ? "active" : null,
          ]}
        >
          Rejected Accounts
        </Button>

        <div className="menuItemTitle">PARTNER MANAGEMENT</div>
        <Button
          as={Link}
          to="/ib-codes"
          onClick={() => handleMenuItemClicked("/ib-codes")}
          className={[
            "menuItem py-2",
            activeItem === "/ib-codes" ? "active" : null,
          ]}
        >
          IB Codes
        </Button>

        <div className="menuItemTitle">FUNDING</div>
        <Button
          as={Link}
          to="/deposit-requests/pending"
          onClick={() => handleMenuItemClicked("/deposit-requests/pending")}
          className={[
            "menuItem py-2",
            activeItem.includes("/deposit-requests") ? "active" : null,
          ]}
        >
          Deposit Requests
          {props.global.newRequests.newDeposits > 0 ? (
            <SidebarCounter number={props.global.newRequests.newDeposits} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/withdrawal-requests/pending"
          onClick={() => handleMenuItemClicked("/withdrawal-requests/pending")}
          className={[
            "menuItem py-2",
            activeItem.includes("/withdrawal-requests") ? "active" : null,
          ]}
        >
          Withdrawal Requests
          {props.global.newRequests.newWithdrawals > 0 ? (
            <SidebarCounter number={props.global.newRequests.newWithdrawals} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/transfer-requests/pending"
          onClick={() => handleMenuItemClicked("/transfer-requests/pending")}
          className={[
            "menuItem py-2",
            activeItem.includes("/transfer-requests") ? "active" : null,
          ]}
        >
          Internal Transfers
          {props.global.newRequests.newTransfers > 0 ? (
            <SidebarCounter number={props.global.newRequests.newTransfers} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/bank-verification-requests/pending"
          onClick={() => handleMenuItemClicked("/bank-verification/pending")}
          className={[
            "menuItem py-2",
            activeItem.includes("/bank-verification") ? "active" : null,
          ]}
        >
          Bank Accounts
          {props.global.newRequests.newBankDetails > 0 ? (
            <SidebarCounter number={props.global.newRequests.newBankDetails} />
          ) : null}
        </Button>

        <div className="menuItemTitle">TRADING ACCOUNTS</div>
        <Button
          as={Link}
          to="/wallet-accounts"
          onClick={() => handleMenuItemClicked("/wallet-accounts")}
          className={[
            "menuItem py-2",
            activeItem === "/wallet-accounts" ? "active" : null,
          ]}
        >
          Wallet Accounts
        </Button>
        <Button
          as={Link}
          to="/trading-accounts"
          onClick={() => handleMenuItemClicked("/trading-accounts")}
          className={[
            "menuItem py-2",
            activeItem === "/trading-accounts" ? "active" : null,
          ]}
        >
          Trading Accounts
        </Button>
        <Button
          as={Link}
          to="/new-sub-account-requests/pending"
          onClick={() =>
            handleMenuItemClicked("/new-sub-account-requests/pending")
          }
          className={[
            "menuItem py-2",
            activeItem.includes("/new-sub-account-requests") ? "active" : null,
          ]}
        >
          Sub Account Requests
          {props.global.newRequests.newSubAccounts > 0 ? (
            <SidebarCounter number={props.global.newRequests.newSubAccounts} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/change-leverage-requests/pending"
          onClick={() =>
            handleMenuItemClicked("/change-leverage-requests/pending")
          }
          className={[
            "menuItem py-2",
            activeItem.includes("/change-leverage-requests") ? "active" : null,
          ]}
        >
          Leverage Changes
          {props.global.newRequests.newChangeLeverages > 0 ? (
            <SidebarCounter
              number={props.global.newRequests.newChangeLeverages}
            />
          ) : null}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  global: state.global,
});

export default connect(mapStateToProps)(SidebarAdmin);
