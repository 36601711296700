import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  adminGetBankAccounts,
  adminUpdateBankAccount,
  clearSuccessErrorProps,
} from "../../../redux/actions/adminActions";
import { API_URL } from "../../../config/config";
import { countryList } from "../../../config/constants";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import moment from "moment";
import {
  getAccountStatusSelectListOptions,
  countryListReactSelect,
} from "../../../utils/utils";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// Register the plugin
registerPlugin(FilePondPluginFileRename, FilePondPluginImagePreview);

const BankAccounts = props => {
  const [state, setState] = useState({
    editAccountErrors: "",
    showAccountModal: false,
    disableEditAccountBtn: false,
    selectedAccount: {},
    loading: true,
  });
  const [bankAccounts, setBankAccounts] = useState(null);
  const [poaFile, setPoaFile] = useState(null);
  const [country, setCountry] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    triggerAdminGetBankAccounts();
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      createNotification("success");

      clearSuccessErrorProps();
      handleCloseModal();
      triggerAdminGetBankAccounts();
    }

    if (
      Object.keys(props.errors).length > 0 &&
      props.errors.account !== undefined
    ) {
      createNotification("error");

      setState(prevState => {
        return {
          ...prevState,
          editAccountErrors: props.errors.account,
        };
      });
      clearSuccessErrorProps();
    }
  }, [props]);

  const triggerAdminGetBankAccounts = () => {
    setState(prevState => {
      return {
        ...prevState,
        loading: true,
      };
    });
    adminGetBankAccounts("all", "all")
      .then(res => {
        setUsers(res.data.users);

        setBankAccounts(res.data.bankAccounts);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.error(err));
  };

  const handleUpdateAccount = e => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "changeDocument") {
      value = e.target.checked;
    }

    const selectedAccount = state.selectedAccount;
    selectedAccount[name] = value;
    setState(prevState => {
      return { ...prevState, selectedAccount };
    });
  };

  const handleChangeCountry = e => {
    setCountry(e.value);
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        disableEditAccountBtn: false,
        showAccountModal: false,
      };
    });
  };

  const handleSubmit = () => {
    let { selectedAccount } = state;

    if (
      selectedAccount.status === "rejected" &&
      (!selectedAccount.comment || selectedAccount.comment.trim() === "")
    ) {
      alert("You cannot reject account without a reason");
      return false;
    }

    setState(prevState => {
      return { ...prevState, disableEditAccountBtn: true };
    });

    if (country) {
      selectedAccount = {
        ...selectedAccount,
        bankCountry: country,
      };
    }
    if (selectedAccount.changeDocument) {
      selectedAccount = {
        ...selectedAccount,
        bankPOA: poaFile,
      };
    }

    props.adminUpdateBankAccount(selectedAccount);
  };

  const createNotification = type => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the bank details request.",
          "Bank Details Update",
          5000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong!", "Error", 5000);
        break;
      default:
    }
  };

  const columns = [
    {
      dataField: "fullName",
      text: "FULL NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "bankName",
      text: "BANK NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "timestampText",
      text: "ADDED",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestampText",
      order: "asc",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setState(prevState => {
        return { ...prevState, showAccountModal: true, selectedAccount: row };
      });
    },
  };

  let data = {};
  if (!!bankAccounts) {
    Object.keys(bankAccounts).forEach(bankAccGroup => {
      data[bankAccGroup] = [];
      bankAccounts[bankAccGroup].forEach((bankAcc, keyIndex) => {
        // Some data formatting before displaying
        bankAccounts[bankAccGroup][keyIndex].timestampText = moment(
          bankAccounts[bankAccGroup][keyIndex].timestamp * 1000
        )
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();

        if (users && users[bankAccounts[bankAccGroup][keyIndex].userId]) {
          bankAccounts[bankAccGroup][keyIndex].fullName = `${
            users[bankAccounts[bankAccGroup][keyIndex].userId].name
          } ${users[bankAccounts[bankAccGroup][keyIndex].userId].surname}`;
          bankAccounts[bankAccGroup][keyIndex].email =
            users[bankAccounts[bankAccGroup][keyIndex].userId].email;
        }

        data[bankAccGroup].push(bankAccounts[bankAccGroup][keyIndex]);
      });
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="pt-3 px-3">
            Bank Accounts{" "}
            {state.loading ? <Spinner animation="border" /> : null}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="pending"
            transition={false}
            id="bank-accounts-tabs"
          >
            <Tab eventKey="pending" title="PENDING" className="py-4">
              <BootstrapTable
                bootstrap4
                keyField="_id"
                data={data["pending"] || []}
                columns={columns}
                defaultSorted={defaultSorted}
                hover={true}
                filter={filterFactory()}
                rowEvents={rowEvents}
                headerClasses="tableHeaderClass"
              />
            </Tab>
            <Tab eventKey="approved" title="APPROVED" className="py-4">
              <BootstrapTable
                bootstrap4
                keyField="_id"
                data={data["approved"] || []}
                columns={columns}
                defaultSorted={defaultSorted}
                hover={true}
                filter={filterFactory()}
                rowEvents={rowEvents}
                headerClasses="tableHeaderClass"
              />
            </Tab>
            <Tab eventKey="rejected" title="DECLINED" className="py-4">
              <BootstrapTable
                bootstrap4
                keyField="_id"
                data={data["rejected"] || []}
                columns={columns}
                defaultSorted={defaultSorted}
                hover={true}
                filter={filterFactory()}
                rowEvents={rowEvents}
                headerClasses="tableHeaderClass"
              />
            </Tab>
          </Tabs>

          <Modal
            show={state.showAccountModal}
            onHide={handleCloseModal}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Bank details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>
                    Bank Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="bankName"
                    className="roundedBox"
                    onChange={e => handleUpdateAccount(e)}
                    defaultValue={state.selectedAccount.bankName}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>
                    Bank Address <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="bankAddress"
                    className="roundedBox"
                    onChange={e => handleUpdateAccount(e)}
                    defaultValue={state.selectedAccount.bankAddress}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                {state.selectedAccount.bankCountry === "GB" ? (
                  <>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label>
                        Account Number <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="bankAccountNumber"
                        className="roundedBox"
                        onChange={e => handleUpdateAccount(e)}
                        defaultValue={state.selectedAccount.bankAccountNumber}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label>Bank SortCode</Form.Label>
                      <Form.Control
                        type="text"
                        name="bankSortCode"
                        className="roundedBox"
                        onChange={e => handleUpdateAccount(e)}
                        defaultValue={state.selectedAccount.bankSortCode}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label>IBAN</Form.Label>
                      <Form.Control
                        type="text"
                        name="bankIBAN"
                        className="roundedBox"
                        onChange={e => handleUpdateAccount(e)}
                        defaultValue={state.selectedAccount.bankIBAN}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" xs="12">
                      <Form.Label>
                        Bank Swift <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="bankSwift"
                        className="roundedBox"
                        onChange={e => handleUpdateAccount(e)}
                        defaultValue={state.selectedAccount.bankSwift}
                      />
                    </Form.Group>
                  </>
                )}
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>
                    Bank Country <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    name="country"
                    required
                    options={countryListReactSelect()}
                    onChange={e => handleChangeCountry(e)}
                    defaultValue={{
                      label: countryList[state.selectedAccount.bankCountry],
                      value: state.selectedAccount.bankCountry,
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Account currency</Form.Label>
                  <Form.Control
                    as="select"
                    name="bankAccountCurrency"
                    className="roundedBox"
                    onChange={e => handleUpdateAccount(e)}
                    defaultValue={state.selectedAccount.bankAccountCurrency}
                  >
                    <option value="">Select base currency</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="USD">USD</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Bank statement:</Form.Label>
                  {state.selectedAccount.bankPOA ? (
                    <a
                      href={`${API_URL}/public/files/${state.selectedAccount.bankPOA}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                  ) : (
                    <span className="text-danger"> Missing!</span>
                  )}
                  <Form.Group
                    controlId="changeDocument"
                    className="d-inline-block float-right m-0"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Upload new document"
                      name="changeDocument"
                      checked={state.selectedAccount.changeDocument}
                      onChange={e => handleUpdateAccount(e)}
                    />
                  </Form.Group>
                  {state.selectedAccount.changeDocument ? (
                    <FilePond
                      onupdatefiles={fileItems => {
                        if (fileItems.length > 0) {
                          setPoaFile(prevState => {
                            let files = fileItems.map(
                              fileItem => fileItem.file
                            );
                            if (files.length > 0) {
                              return files[0].name;
                            }
                          });
                        }
                      }}
                      fileRenameFunction={file => {
                        let date = new Date();
                        let time = date.getTime();
                        //prettier-ignore
                        return `${state.selectedAccount.userId}_${time}_${file.basename}${file.extension}`;
                      }}
                      name={"file"}
                      server={`${API_URL}/upload`}
                    />
                  ) : null}
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="2"
                  name="comment"
                  onChange={e => handleUpdateAccount(e)}
                  defaultValue={state.selectedAccount.comment}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  required
                  onChange={e => handleUpdateAccount(e)}
                  defaultValue={state.selectedAccount.status}
                >
                  <option value="">Choose account status</option>
                  {getAccountStatusSelectListOptions()}
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-between">
              <Button
                variant="secondary"
                className="grayButton"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                className="blueButton"
                style={{
                  backgroundColor: "var(--color-dark-blue1)",
                }}
                onClick={handleSubmit}
                disabled={state.disableEditAccountBtn}
              >
                {state.disableEditAccountBtn ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Submit
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

BankAccounts.propTypes = {
  auth: PropTypes.object.isRequired,
  adminUpdateBankAccount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { adminUpdateBankAccount })(
  BankAccounts
);
