import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  adminGetWalletAccounts,
  // adminUpdateAccount,
} from "../../../redux/actions/adminActions";
import {
  // filterAccountPlatform,
  filterBaseCurrency,
} from "../../../config/constants";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import moment from "moment";
// import { NotificationManager } from "react-notifications";

const WalletAccounts = props => {
  const [state, setState] = useState({
    editAccountErrors: "",
    showAccountModal: false,
    disableEditAccountBtn: false,
    selectedAccount: {},
    loading: true,
  });
  const [accountsLive, setLiveAccounts] = useState(null);
  const [usersLive, setLiveUsers] = useState(null);

  useEffect(() => {
    triggerAdminGetWalletAccounts();
  }, []);

  const triggerAdminGetWalletAccounts = () => {
    setState(prevState => {
      return {
        ...prevState,
        loading: true,
      };
    });
    adminGetWalletAccounts("all", "live", "approved")
      .then(res => {
        let users = {};
        res.data.users.map(user => (users[user._id] = user));
        setLiveUsers(users);

        setLiveAccounts(res.data.accounts);
        setState(prevState => {
          return {
            ...prevState,
            loading: false,
          };
        });
      })
      .catch(err => console.error(err));
  };

  const customFilterCurrency = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.baseCurrency === filterVal);
    }
    return data;
  };

  const customFilterAccountPlatform = (filterVal, data) => {
    if (filterVal.length > 0) {
      return data.filter(user => user.platform === filterVal);
    }
    return data;
  };

  const columnsLive = [
    {
      dataField: "fullName",
      text: "FULL NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "number",
      text: "ACC. NUMBER",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "platform",
      text: "PLATFORM",
      // sort: true,
      // filter: selectFilter({
      //   options: filterAccountPlatform,
      //   onFilter: customFilterAccountPlatform,
      // }),
    },
    {
      dataField: "balance",
      text: "BALANCE",
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "baseCurrency",
      text: "BASE CURRENCY",
      sort: true,
      filter: selectFilter({
        options: filterBaseCurrency,
        onFilter: customFilterCurrency,
      }),
    },
    {
      dataField: "timeCreatedText",
      text: "CREATED",
      sort: true,
    },
    {
      dataField: "timeApprovedText",
      text: "APPROVED",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timeApprovedText",
      order: "asc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  });

  let dataLive = [];
  if (!!accountsLive) {
    accountsLive.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      accountsLive[keyIndex].timeCreatedText = moment(
        accountsLive[keyIndex].timeCreated * 1000
      )
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      accountsLive[keyIndex].timeApprovedText = moment(
        accountsLive[keyIndex].timeApproved * 1000
      )
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      accountsLive[keyIndex].balance = parseFloat(
        accountsLive[keyIndex].balance
      ).toFixed(2);

      if (usersLive && usersLive[accountsLive[keyIndex].userId]) {
        accountsLive[keyIndex].fullName = `${
          usersLive[accountsLive[keyIndex].userId].name
        } ${usersLive[accountsLive[keyIndex].userId].surname}`;
        accountsLive[keyIndex].email =
          usersLive[accountsLive[keyIndex].userId].email;
      }

      dataLive.push(accountsLive[keyIndex]);
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1 className="pt-3 px-3">
            Wallet Accounts{" "}
            {state.loading ? <Spinner animation="border" /> : null}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <BootstrapTable
            bootstrap4
            keyField="_id"
            data={dataLive}
            columns={columnsLive}
            defaultSorted={defaultSorted}
            hover={true}
            pagination={pagination}
            filter={filterFactory()}
            headerClasses="tableHeaderClass"
          />
        </Col>
      </Row>
    </Container>
  );
};

WalletAccounts.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(WalletAccounts);
