import React from "react";
import { Col, Form, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { API_URL } from "../../../config/config";
import { countryList } from "../../../config/constants";

const BankDetails = props => {
  return (
    <Modal show={true} onHide={() => props.handleModalClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Bank Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Row>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>Bank Country</Form.Label>
            <Form.Control
              type="text"
              className="roundedBox"
              defaultValue={countryList[props.bankDetails.bankCountry]}
              disabled={true}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>Account currency</Form.Label>
            <Form.Control
              type="text"
              className="roundedBox"
              defaultValue={props.bankDetails.bankAccountCurrency}
              disabled={true}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>Bank Name</Form.Label>
            <Form.Control
              type="text"
              className="roundedBox"
              defaultValue={props.bankDetails.bankName}
              disabled={true}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" xs="12">
            <Form.Label>Bank Address</Form.Label>
            <Form.Control
              type="text"
              className="roundedBox"
              defaultValue={props.bankDetails.bankAddress}
              disabled={true}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          {props.bankDetails.country === "GB" ? (
            <>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type="text"
                  className="roundedBox"
                  defaultValue={props.bankDetails.bankAccountNumber}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Bank SortCode</Form.Label>
                <Form.Control
                  type="text"
                  className="roundedBox"
                  defaultValue={props.bankDetails.bankSortCode}
                  disabled={true}
                />
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>IBAN</Form.Label>
                <Form.Control
                  type="text"
                  className="roundedBox"
                  defaultValue={props.bankDetails.bankIBAN}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label>Bank Swift</Form.Label>
                <Form.Control
                  type="text"
                  className="roundedBox"
                  defaultValue={props.bankDetails.bankSwift}
                  disabled={true}
                />
              </Form.Group>
            </>
          )}
        </Form.Row>

        <Form.Row>
          {props.bankDetails.bankPOA ? (
            <Form.Group as={Col} md="6" xs="12">
              <Form.Label>Bank Statement: </Form.Label>{" "}
              <a
                href={`${API_URL}/public/files/${props.bankDetails.bankPOA}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            </Form.Group>
          ) : (
            <Form.Group as={Col} md="6" xs="12" />
          )}
        </Form.Row>
      </Modal.Body>
    </Modal>
  );
};

BankDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(BankDetails);
