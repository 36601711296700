import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  countryListReactSelect,
  getBirthDaysReactSelect,
  getBirthMonthsReactSelect,
  getBirthYearsReactSelect,
} from "../../../../utils/utils";
import { adminAddUser } from "../../../../redux/actions/adminActions";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";

const AddUser = props => {
  const [state, setState] = useState({
    newUser: {
      title: "",
      name: "",
      surname: "",
      email: "",
      password: "",
      password2: "",
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      mobilePhone: "",
      addressNo: "",
      address: "",
      city: "",
      postcode: "",
      country: "",
      nationality: "",
    },
    validated: false,
    disableAddUserBtn: false,
    projects: {},
    errors: {},
  });
  const [newUser, setNewUser] = useState({});

  const handleChange = (e, target) => {
    let name;
    let value;

    if (
      target === "country" ||
      target === "nationality" ||
      target === "birthDay" ||
      target === "birthMonth" ||
      target === "birthYear"
    ) {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setNewUser(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setState(prevState => {
      return {
        ...prevState,
        disableAddUserBtn: true,
      };
    });

    if (
      newUser.birthDay === undefined ||
      newUser.birthMonth === undefined ||
      newUser.birthYear === undefined
    ) {
      setState(prevState => {
        return { ...prevState, disableAddUserBtn: false };
      });

      alert("Please, set your birth date.");

      return false;
    }

    if (form.checkValidity()) {
      props.adminAddUser(newUser, props.history);
    }
  };

  // const { user } = props.auth;
  const { validated, errors, disableAddUserBtn } = state;

  return (
    <>
      <h1>
        <b>Add User</b>
      </h1>
      <Container>
        <Row className="justify-content-lg-center">
          <Col>
            <Form validated={validated} onSubmit={e => handleSubmit(e)}>
              <Form.Text style={{ color: "red" }}>{errors.server}</Form.Text>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>{errors.email}</Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.password}
                    {errors.passwordincorrect}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Confirm password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password2"
                    placeholder="Confirm password"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.password2}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="2" xs="6">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    as="select"
                    name="title"
                    onChange={e => handleChange(e, "")}
                  >
                    <option value="">Select title</option>
                    <option>Mr.</option>
                    <option>Mrs.</option>
                    <option>Dr.</option>
                  </Form.Control>
                  <Form.Text style={{ color: "red" }}>{errors.title}</Form.Text>
                </Form.Group>

                <Form.Group as={Col} md="4" xs="6">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>{errors.name}</Form.Text>
                </Form.Group>

                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Surname</Form.Label>
                  <Form.Control
                    type="text"
                    name="surname"
                    placeholder="Enter your surname"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.surname}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Col md="6" xs="12">
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Birth day</Form.Label>
                      <Select
                        name="birthDay"
                        required
                        onChange={e => handleChange(e, "birthDay")}
                        options={getBirthDaysReactSelect()}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Birth month</Form.Label>
                      <Select
                        name="birthMonth"
                        required
                        onChange={e => handleChange(e, "birthMonth")}
                        options={getBirthMonthsReactSelect()}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Birth year</Form.Label>
                      <Select
                        name="birthYear"
                        required
                        onChange={e => handleChange(e, "birthYear")}
                        options={getBirthYearsReactSelect()}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Mobile phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobilePhone"
                    placeholder="Enter your phone number"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.mobilePhone}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Street number</Form.Label>
                  <Form.Control
                    type="text"
                    name="addressNo"
                    placeholder="Enter your street number"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.address}
                  </Form.Text>
                </Form.Group>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Street address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Enter your street address"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.address}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="Enter your city"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>{errors.city}</Form.Text>
                </Form.Group>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Postcode</Form.Label>
                  <Form.Control
                    type="text"
                    name="postcode"
                    placeholder="Enter your postcode"
                    required
                    onChange={e => handleChange(e, "")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.postcode}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>County</Form.Label>
                  <Form.Control
                    type="text"
                    name="county"
                    placeholder="Enter your county"
                    onChange={e => handleChange(e, "county")}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.county}
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Country</Form.Label>
                  <Select
                    name="country"
                    required
                    onChange={e => handleChange(e, "country")}
                    options={countryListReactSelect()}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.country}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="6" xs="12">
                  <Form.Label>Nationality</Form.Label>
                  <Select
                    name="nationality"
                    required
                    onChange={e => handleChange(e, "nationality")}
                    options={countryListReactSelect()}
                  />
                  <Form.Text style={{ color: "red" }}>
                    {errors.nationality}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row className="justify-content-between">
                <Button variant="secondary" as={Link} to="/users">
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={disableAddUserBtn}
                >
                  {disableAddUserBtn ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Submit
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form.Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

AddUser.propTypes = {
  adminAddUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { adminAddUser })(AddUser);
