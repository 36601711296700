import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classes from "./Profile.module.css";
import { SUMSUB_API_URL } from "../../../../config/config";
import { getKYCDocuments } from "../../../../redux/actions/adminActions";
import { Button, Card } from "react-bootstrap";
import IconApproved from "../../../../assets/images/icon-approved.svg";
import IconRejected from "../../../../assets/images/icon-rejected.svg";
import {
  FiSearch,
  // FiDownload
} from "react-icons/fi";

const KYCDocuments = props => {
  const [docs, setDocs] = useState({});
  const [KYCData, setKYCData] = useState(null);

  useEffect(() => {
    if (props.userData !== false) {
      setKYCData(props.userData.eKYCApproved);

      getKYCDocuments(props.userData._id)
        .then(res => {
          setDocs(res.data);
        })
        .catch(err => console.log(err));
    }
  }, [props.userData]);

  return (
    <Card className={classes.card}>
      <Card.Body className="p-0">
        <p
          className={[
            "p-3 m-0 border-bottom fontWeight500",
            classes.cardHeader,
          ].join(" ")}
        >
          KYC Documents
          {Object.keys(docs).length > 1 ? (
            <span className="statusFlag statusFlagApproved mx-4 fontSize11px">
              <img src={IconApproved} alt="" /> Uploaded
            </span>
          ) : (
            <span className="statusFlag statusFlagRejected mx-4 fontSize11px">
              <img src={IconRejected} alt="" /> Uploaded
            </span>
          )}
          <small className="fontWeight500 fontSize11px">eKYC Verified:</small>{" "}
          {KYCData ? (
            <img src={IconApproved} alt="" />
          ) : (
            <img src={IconRejected} alt="" />
          )}
          <small className="fontWeight500 ml-4 fontSize11px">
            eKYC Report:{" "}
            <Button
              type="button"
              variant="link"
              className="fontWeight500 text-dark p-0 textDecorationUnderline fontSize11px"
            >
              LINK
            </Button>
          </small>
          {/*<Button*/}
          {/*  type="button"*/}
          {/*  variant="link"*/}
          {/*  className="smallGreenButton mt-1 text-white"*/}
          {/*>*/}
          {/*  + ADD*/}
          {/*</Button>*/}
        </p>

        {docs["IDENTITY"] ? (
          <>
            <p
              className={[
                classes.complianceText,
                "mt-3 ml-3 font-weight-bold fontSize13px",
              ].join(" ")}
            >
              Proof of Identity
            </p>

            {docs["IDENTITY"].imageIds.map((file, key) => (
              <p
                key={key}
                className={[
                  classes.complianceText,
                  "mt-3 mx-3 fontSize11px borderBottomGray clearfix",
                ].join(" ")}
              >
                {props.userData.name} {props.userData.surname} POI -{" "}
                {docs["IDENTITY"].idDocType.replace("_", " ")}
                {docs["IDENTITY"].imageIds.length >= 2
                  ? key === 0
                    ? " - Front"
                    : " - Back"
                  : null}
                {/*<Button*/}
                {/*  variant="link"*/}
                {/*  className="smallRedButton text-white float-right ml-5 mb-1"*/}
                {/*>*/}
                {/*  <FiDownload /> DOWNLOAD*/}
                {/*</Button>*/}
                <Button
                  variant="link"
                  href={`${SUMSUB_API_URL}/resources/inspections/${docs["inspectionId"]}/resources/${file}`}
                  target="_blank"
                  className="smallLightBlueButton text-white float-right mr-3 mb-1"
                >
                  <FiSearch /> VIEW
                </Button>
              </p>
            ))}
          </>
        ) : null}

        {docs["PROOF_OF_RESIDENCE"] ? (
          <>
            <p
              className={[
                classes.complianceText,
                "mt-3 ml-3 font-weight-bold fontSize13px",
              ].join(" ")}
            >
              Proof of Address
            </p>

            {docs["PROOF_OF_RESIDENCE"].imageIds.map((file, key) => (
              <p
                key={key}
                className={[
                  classes.complianceText,
                  "mt-3 mx-3 fontSize11px borderBottomGray clearfix",
                ].join(" ")}
              >
                {props.userData.name} {props.userData.surname} POA -{" "}
                {docs["PROOF_OF_RESIDENCE"].idDocType.replace("_", " ")}
                {docs["PROOF_OF_RESIDENCE"].imageIds.length >= 2
                  ? key === 0
                    ? " - Front"
                    : " - Back"
                  : null}
                {/*<Button*/}
                {/*  variant="link"*/}
                {/*  className="smallRedButton text-white float-right ml-5 mb-1"*/}
                {/*>*/}
                {/*  <FiDownload /> DOWNLOAD*/}
                {/*</Button>*/}
                <Button
                  variant="link"
                  href={`${SUMSUB_API_URL}/resources/inspections/${docs["inspectionId"]}/resources/${file}`}
                  target="_blank"
                  className="smallLightBlueButton text-white float-right mr-3 mb-1"
                >
                  <FiSearch /> VIEW
                </Button>
              </p>
            ))}
          </>
        ) : null}
      </Card.Body>
    </Card>
  );
};

KYCDocuments.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(KYCDocuments);
